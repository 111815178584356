import React, { useState, useEffect } from "react";

const StoryPage = () => {
  const [stories, setStories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const storiesPerPage = 10;

  useEffect(() => {
    // Mock data for stories
    const mockStories = Array.from({ length: 30 }, (_, index) => ({
      id: index + 1,
      title: `Chapter ${index + 1}`,
      content: `This is the content of story chapter ${
        index + 1
      }. Grumble faces many challenges and learns valuable lessons along the way.`,
      imageUrl: `https://via.placeholder.com/150?text=Chapter+${index + 1}`,
    }));
    setStories(mockStories);
  }, []);

  // Pagination logic
  const indexOfLastStory = currentPage * storiesPerPage;
  const indexOfFirstStory = indexOfLastStory - storiesPerPage;
  const currentStories = stories.slice(indexOfFirstStory, indexOfLastStory);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-green-100 min-h-screen font-sans px-4">
      <h1 className="text-3xl font-bold text-mossGreen my-8 text-center">
        Grumble's Story
      </h1>
      <div className="grid grid-cols-1 gap-8">
        {currentStories.map((story) => (
          <div key={story.id} className="bg-white p-6 rounded-lg shadow-md">
            <img
              src={story.imageUrl}
              alt={story.title}
              className="w-full h-40 object-cover mb-4 rounded-md"
            />
            <h2 className="text-2xl font-bold text-mossGreen mb-2">
              {story.title}
            </h2>
            <p className="text-lg text-gray-700">{story.content}</p>
          </div>
        ))}
      </div>
      <div className="flex justify-center my-8">
        {Array.from(
          { length: Math.ceil(stories.length / storiesPerPage) },
          (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`mx-1 px-3 py-2 ${
                currentPage === index + 1
                  ? "bg-mossGreen text-white"
                  : "bg-white text-mossGreen"
              } rounded-full border border-mossGreen`}
            >
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default StoryPage;
