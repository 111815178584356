import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="bg-mossGreen text-white p-4 rounded-full inline-block mt-5">
      <ul className="flex justify-start font-semibold space-x-10 px-10">
        <li>
          <a
            href="/#about"
            className="text-white hover:animate-pulse transition duration-300"
            onClick={() => {
              const aboutSection = document.getElementById("about");
              if (aboutSection) {
                aboutSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            About
          </a>
        </li>
        <li>
          <a
            href="/#how-it-works"
            className="text-white hover:animate-pulse transition duration-300"
            onClick={() => {
              const howItWorksSection = document.getElementById("how-it-works");
              if (howItWorksSection) {
                howItWorksSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            How It Works
          </a>
        </li>
        <li>
          <a
            href="/#journey-starts"
            className="text-white hover:animate-pulse transition duration-300"
            onClick={() => {
              const journeyStartsSection =
                document.getElementById("journey-starts");
              if (journeyStartsSection) {
                journeyStartsSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Journey Starts
          </a>
        </li>
        <li>
          <a
            href="/#chapters"
            className="text-white hover:animate-pulse transition duration-300"
            onClick={() => {
              const chaptersSection = document.getElementById("chapters");
              if (chaptersSection) {
                chaptersSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Chapters
          </a>
        </li>
        <li>
          <a
            href="/#help-grumble"
            className="text-white hover:animate-pulse transition duration-300"
            onClick={() => {
              const helpGrumbleSection =
                document.getElementById("help-grumble");
              if (helpGrumbleSection) {
                helpGrumbleSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Help Grumble
          </a>
        </li>
        <li>
          <a
            href="/#launch-date"
            className="text-white hover:animate-pulse transition duration-300"
            onClick={() => {
              const launchDateSection = document.getElementById("launch-date");
              if (launchDateSection) {
                launchDateSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Launch Date
          </a>
        </li>
        <li>
          <a
            href="/#join-us"
            className="text-white hover:animate-pulse transition duration-300"
            onClick={() => {
              const joinUsSection = document.getElementById("join-us");
              if (joinUsSection) {
                joinUsSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Contacts
          </a>
        </li>
        <li>
          <Link
            to="/story"
            className="hover:animate-pulse transition duration-300 opacity-50 cursor-not-allowed"
            disabled
          >
            Journey
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
