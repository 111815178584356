// Import necessary packages
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/tailwind.css";
import {
  FaTelegram,
  FaTiktok,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa";
import Navbar from "./components/Navbar";
import StoryPage from "./pages/StoryPage";

function App() {
  return (
    <Router>
      <div className="bg-green-100 min-h-screen font-parkinsans relative">
        <div className="w-full flex justify-center z-10 fixed ">
          <div className="hidden md:block">
            <Navbar />
          </div>
        </div>
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/story" element={<StoryPage />} />
        </Routes>
        <footer className="bg-green-200 py-4 text-center text-white absolute bottom-0 w-full">
          <p className="text-mossGreen">© Grumble's Journey 2024</p>
        </footer>
      </div>
    </Router>
  );
}

const MainContent = () => {
  return (
    <main className="w-full relative z-0">
      <img src="/grumblesJourney.jpg" alt="Grumble's Journey" />
      <div className="w-5/6 mx-auto text-justify">
        <section
          id="about"
          className="py-40 flex flex-col md:flex-row gap-10 items-center"
        >
          <div className="h-fit">
            <h1 className="text-3xl font-bold text-mossGreen mb-4 text-center">
              About Grumble's Journey
            </h1>
            <p className="text-lg text-gray-700">
              Step into the enchanting and unpredictable world of Grumble! This
              is no ordinary tale—it’s a journey of humor, heart, and surprises,
              wrapped in an innovative concept that ties storytelling with the
              dynamic world of cryptocurrency. Grumble, a quirky and majestic
              creature, sets out on an epic adventure like no other,
              encountering wild challenges, comedic escapades, and moments of
              transformation that will leave you grinning from ear to ear.
            </p>
          </div>
          <img src="/about.png" alt="About Grumble" className="rounded-3xl" />
        </section>
        <hr className="w-full my-8 border-t-2 border-[#adcc76]" />

        <section
          id="how-it-works"
          className="py-40 flex flex-col md:flex-row-reverse gap-10 items-center"
        >
          <div className="h-fit">
            <h2 className="text-2xl font-bold text-mossGreen mb-4 text-center">
              How It Works
            </h2>
            <p className="text-lg text-gray-700">
              Grumble’s tale isn’t just told—it’s built by the community. The
              performance of Grumble’s meme token drives the narrative. With
              every market cap milestone achieved, a new chapter of his journey
              is revealed, bringing fans closer to discovering what lies ahead.
            </p>
          </div>
          <img src="/grumbleHowItWorks.png" alt="How It Works" width={700} />
        </section>
        <hr className="w-full my-8 border-t-2 border-[#adcc76]" />

        <section
          id="journey-starts"
          className="py-40 flex flex-col md:flex-row gap-10 items-center"
        >
          <div className="h-fit">
            <h2 className="text-2xl font-bold text-mossGreen mb-4 text-center">
              Journey Starts
            </h2>
            <p className="text-lg text-gray-700">
              "In a land where the unexpected reigns supreme, Grumble was born."
              Grumble’s story begins as that of a majestic creature with a big
              heart and a knack for finding himself in the most outrageous
              predicaments. His world is whimsical, filled with oddities and
              eccentric characters, and brimming with opportunities for
              adventure. From the moment Grumble steps out of his comfort zone,
              he embarks on a journey full of laughter, growth, and unexpected
              transformations.
            </p>
          </div>
          <img src="/start.png" alt="Journey Starts" className="rounded-3xl" />
        </section>
        <hr className="w-full my-8 border-t-2 border-[#adcc76]" />

        <section
          id="chapters"
          className="py-40 flex flex-col md:flex-row-reverse gap-10 items-center"
        >
          <div className="h-fit">
            <h2 className="text-2xl font-bold text-mossGreen mb-4 text-center">
              Chapters Grumble Passed
            </h2>
            <p className="text-lg text-gray-700">
              As Grumble moves forward, each milestone represents a chapter in
              his life. These aren’t just ordinary accomplishments; they’re
              pivotal moments filled with excitement, hilarity, and surprises.
              Each chapter dives deeper into the whimsical world of Grumble,
              unveiling his struggles, triumphs, and encounters with eccentric
              characters that make his journey so unforgettable.
            </p>
          </div>
          <img src="/chapters.png" alt="Chapters" className="rounded-3xl" />
        </section>

        <hr className="w-full my-8 border-t-2 border-[#adcc76]" />

        <section
          id="help-grumble"
          className="py-40 flex flex-col md:flex-row gap-10 items-center"
        >
          <div className="h-fit">
            <h2 className="text-2xl font-bold text-mossGreen mb-4 text-center">
              HELP GRUMBLE
            </h2>
            <p className="text-lg text-gray-700">
              "Every trade counts! Help Grumble reach new heights!" Grumble’s
              journey thrives on community support. Every buy, hold, or trade of
              the meme token pushes him closer to his next milestone, unlocking
              new chapters and adventures. The more the community grows, the
              farther Grumble can go!
            </p>
          </div>
          <img
            src="/helpGrumble.png"
            alt="Help Grumble"
            className="rounded-3xl"
          />
        </section>
        <hr className="w-full my-8 border-t-2 border-[#adcc76]" />

        <section id="launch-date" className="py-8">
          <div className="h-fit">
            <h2 className="text-2xl font-bold text-mossGreen mb-4 text-center">
              Launch Date
            </h2>
            <h2 className="text-2xl font-bold text-mossGreen mb-4 text-center">
              TBA
            </h2>
          </div>
        </section>
        <hr className="w-full my-8 border-t-2 border-[#adcc76]" />

        <section id="join-us" className="py-40">
          <h2 className="text-2xl font-bold text-mossGreen mb-4 text-center">
            Join Us!
          </h2>
          <div className="flex justify-center space-x-4">
            {/* <button
              className="text-mossGreen text-3xl"
              onClick={() => window.open("https://telegram.org", "_blank")}
            >
              <FaTelegram />
            </button> */}
            <button
              className="text-mossGreen text-3xl"
              onClick={() =>
                window.open("https://www.tiktok.com/@grumblesjourney", "_blank")
              }
            >
              <FaTiktok />
            </button>
            <button
              className="text-mossGreen text-3xl"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/grumblesjourney",
                  "_blank"
                )
              }
            >
              <FaInstagram />
            </button>
            <button
              className="text-mossGreen text-3xl"
              onClick={() =>
                window.open("https://x.com/grumblesjourney", "_blank")
              }
            >
              <FaTwitterSquare />
            </button>
          </div>
        </section>
      </div>
    </main>
  );
};

export default App;
